import { ArrowForwardIos as BaseArrowForwardIos } from "@mui/icons-material";
import { Button as BaseButton, Typography } from "@mui/material";
import { navigate } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import IndexJsonLd from "../components/IndexjsonLd";
import LoadingCircular from "../components/loadingCircular";
import topBackground from "../images/background.png";
import hyaku from "../images/thanks.svg";
import { default as Seo } from "../layouts/head";
import Layout from "../layouts/pc";
import LayoutSP from "../layouts/sp";
import { AREA_NAME } from "../utils/Area";

const Thanks = () => {
  const breakpoints = useBreakpoint();
  const [loading, setLoading] = useState(true);
  const title = "送信完了";
  useEffect(() => {
    setTimeout(() => setLoading(false), 50);
  }, []);
  return (
    <>
      <Seo title={title} />
      <IndexJsonLd title={title} />
      {loading ? (
        <LoadingCircular />
      ) : breakpoints.sm ? (
        <LayoutSP>
          <MainDiv>
            <ImgDiv>
              <Img src={hyaku} />
            </ImgDiv>
            <TitleDiv>
              <Title>送信完了</Title>
            </TitleDiv>
            <DescriptionDiv>
              <Description>送信が完了しました。</Description>
              <Description>
                お問い合わせいただきありがとうございました。
              </Description>
            </DescriptionDiv>
            <ButtonDiv>
              <TopButton
                onClick={() => navigate(`/?areaName=${AREA_NAME.JAPAN}`)}
              >
                TOPページへ <ArrowForwardIos />
              </TopButton>
            </ButtonDiv>
          </MainDiv>
        </LayoutSP>
      ) : (
        <Layout>
          <MainDiv>
            <ImgDiv>
              <Img src={hyaku} />
            </ImgDiv>
            <TitleDiv>
              <Title>送信完了</Title>
            </TitleDiv>
            <DescriptionDiv>
              <Description>送信が完了しました。</Description>
              <Description>
                お問い合わせいただきありがとうございました。
              </Description>
            </DescriptionDiv>
            <ButtonDiv>
              <TopButton
                onClick={() => navigate(`/?areaName=${AREA_NAME.JAPAN}`)}
              >
                TOPページへ <ArrowForwardIos />
              </TopButton>
            </ButtonDiv>
          </MainDiv>
        </Layout>
      )}
    </>
  );
};

const MainDiv = styled.div`
  background-image: url(${topBackground});
  padding: 32px;
`;

const ImgDiv = styled.div`
  margin-bottom: 16px;
`;

const Img = styled.img`
  width: 160px;
  display: block;
  margin: 0 auto;
`;

const TitleDiv = styled.div`
  margin-bottom: 16px;
`;

const Title = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.colors.black.primary};
    font-size: ${(props) =>
      props.issp ? `${theme.fonts.size.sm}` : `${theme.fonts.size.xl4}`};
    letter-spacing: ${(props) =>
      props.issp
        ? `${theme.fonts.spacing.primary}`
        : `${theme.fonts.spacing.primarySP}`};
    text-align: center;
    font-weight: ${theme.fonts.weight.bold};
  `}
`;
const DescriptionDiv = styled.div`
  margin-bottom: 16px;
`;

const Description = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.colors.black.primary};
    font-size: ${(props) =>
      props.issp ? `${theme.fonts.size.sm}` : `${theme.fonts.size.base}`};
    letter-spacing: ${(props) =>
      props.issp
        ? `${theme.fonts.spacing.primary}`
        : `${theme.fonts.spacing.primarySP}`};
    text-align: center;
  `}
`;

const ButtonDiv = styled.div``;

const TopButton = styled(BaseButton)`
  ${({ theme }) => css`
    padding: 23px 22px;
    color: ${theme.colors.white.primary};
    font-size: ${theme.fonts.size.base};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    background-color: ${theme.colors.purple.primary};
    border-radius: 10px;
    min-width: 275px;
    display: block;
    margin: auto;

    &:hover {
      color: ${theme.colors.white.primary};
      background-color: ${theme.colors.purple.primary};
    }
  `}
`;

const ArrowForwardIos = styled(BaseArrowForwardIos)`
  ${({ theme }) => css`
    color: ${theme.colors.white.primary};
    font-size: ${theme.fonts.size.base};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    line-height: 32px;
  `}
`;

export default Thanks;
