import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import logo from "../images/logo.png";
import ogpThumbnail from "../images/ogp.png";
const IndexJsonLd = ({ title, description }) => {
  const { site } = useStaticQuery(query);

  const { siteUrl, author, defaultDescription } = site.siteMetadata;

  const jsonLdStructuredData = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    image: {
      "@type": "ImageObject",
      url: `${siteUrl}${ogpThumbnail}`,
      height: 1200,
      width: 630,
    },
    url: siteUrl,
    name: title,
    author: {
      "@type": "Person",
      name: author,
    },
    description: description || defaultDescription,
    publisher: {
      "@type": "Organization",
      name: author,
      url: siteUrl,
      logo: {
        "@type": "ImageObject",
        url: `${siteUrl}${logo}`,
        width: 300,
        height: 300,
      },
    },
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(jsonLdStructuredData)}
      </script>
    </Helmet>
  );
};

export default IndexJsonLd;

const query = graphql`
  query Metadata {
    site {
      siteMetadata {
        siteUrl
        defaultImage
      }
    }
  }
`;
